import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';

import { ImageUploadModule } from './common/components/image-upload/image-upload.module';
import { EditableModule } from './common/components/editable/editable.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule, 
    ImageUploadModule,
    EditableModule
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
