import { OnChanges, Input, Output, EventEmitter } from '@angular/core';


export class EditableComponent implements OnChanges {
    @Input() entity: any;
    @Input() set field(entityField: string) {
        this.entityField = entityField;
    };
    @Input() className: string;
    @Input() style: any;
    @Output() entityUpdated = new EventEmitter();

    isActiveInput: boolean = false;

    constructor() { }

    public entityField: string;
    public originalEntityValue: any;

    ngOnChanges() {
        this.setOriginValue;
        this.isActiveInput = false;
    }

    updateEntity() {
        const entityValue = this.entity[this.entityField];

        if (entityValue !== this.originalEntityValue) {
            this.entityUpdated.emit({[this.entityField]: this.entity[this.entityField]});
            this.setOriginValue();
        }
    }

    setOriginValue() {
        this.originalEntityValue = this.entity[this.entityField];
    }
}